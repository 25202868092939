import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { RHFTextField } from '../../../../../components/hook-form';

// eslint-disable-next-line react/prop-types
const GlobalMinMaxInput = ({ name, label, index }) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  console.log('NAME', name);
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: 2,
        columnGap: 3,
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'start',
        borderRight: isDesktop && (index + 1) % 3 !== 0 ? '1px solid ' : 'none',
        pr: isDesktop && (index + 1) % 3 !== 0 ? 2 : 0,
      }}
    >
      <Typography sx={{ gridColumn: 'span 3', textAlign: 'center' }}>{label}</Typography>
      <RHFTextField name={`${name}.min`} label="MIN" vairant={'standard'} />
      <RHFTextField name={`${name}.perTradeLimit`} label="Per Trade Limit" />
      <RHFTextField name={`${name}.max`} label="MAX" />
    </Box>
  );
};

export default GlobalMinMaxInput;
