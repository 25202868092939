import React, { useEffect } from 'react';
import { Typography, Box, IconButton, Grid, InputAdornment } from '@mui/material';
import { Add, Close as CloseIcon, Remove } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import modifyScriptName from '../../../../utils/modifyScriptName';
import axiosInstance from '../../../../utils/axios';
import {
  getUserPortfolio,
  cancelTrade,
  startTradeLoading,
  getTradeHistory,
  stopTradeLoading,
} from '../../../../redux/slices/trades';
import { Wrapper } from '../watchlist/BuyScriptDrawer';
import { RHFTextField, FormProvider, RHFToggleButtonGroup, RHFCheckbox } from '../../../../components/hook-form/index';
import useAuth from '../../../../hooks/useAuth';
import { fNumber } from '../../../../utils/formatNumber';

const CloseTradeModal = ({
  trade,
  isOpen,
  onClose,
  closeFromTrade = false,
  closeFromPortfolio = false,
  onUpdateSuccess,
  isManualTrade,
}) => {
  const isLoading = useSelector((state) => state?.trade?.isLoading);
  const { user } = useAuth();

  const schema = yup.object().shape({
    quantity: yup
      .number()
      .required('Quantity is required')
      .typeError('Quantity must be a number')
      .positive('Quantity must be greater than zero')
      .when([], {
        is: () => trade.type === 'OPEN',
        then: yup.number().max(trade.quantity, `Quantity cannot exceed ${trade.quantity}`),
      }),
    tradeAction: yup.string().required('Action is required'),
    price: yup
      .number()
      .typeError('Price must be a number')
      .positive('Price must be greater than zero')
      .when(['useCustomPrice', 'tradeAction'], {
        is: (useCustomPrice, tradeAction) => useCustomPrice || tradeAction === 'modify',
        then: yup.number().required('Price is required'),
      }),
    useCustomPrice: yup.boolean(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      quantity: trade.quantity,
      tradeAction: 'cancel',
      useCustomPrice: false,
    },
  });

  const { handleSubmit, reset, watch, setValue } = methods;
  const selectedOption = watch('tradeAction');
  const useCustomPrice = watch('useCustomPrice');

  useEffect(() => {
    if (!isOpen) {
      reset({
        quantity: trade.quantity,
        tradeAction: 'cancel',
        price: trade.side === 'BUY' ? trade.buy : trade.sell,
        useCustomPrice: false,
      });
    }
  }, [isOpen, reset, trade.quantity, trade.buy, trade.sell, trade.side]);

  useEffect(() => {
    if (useCustomPrice) {
      setValue('price', 0);
    }
    // } else {
    //   setValue('price', trade.side === 'BUY' ? trade.buy : trade.sell);
    // }
  }, [useCustomPrice, setValue, trade.buy, trade.sell, trade.side]);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleCloseTrade = async (data) => {
    dispatch(startTradeLoading());
    let response;

    try {
      if (closeFromPortfolio) {
        data.side = trade.side === 'BUY' ? 'SELL' : 'BUY';
        data.orderType = 'MARKET';
        data.scriptId = trade.script._id;
        data.cancelFromPortfolio = true;
        response = await axiosInstance.post('/trade/add', data);
      } else if (closeFromTrade) {
        response = await axiosInstance.put(`/trade/close-trade/${trade._id}`, data);
      }
      enqueueSnackbar(response.data.message);
      if (response.data.tradesCancelled) {
        enqueueSnackbar(response.data.cancelMessage, { persist: true });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      if (closeFromPortfolio && !isManualTrade) {
        dispatch(getUserPortfolio());
        onClose();
      }
      if (closeFromTrade && !isManualTrade) {
        dispatch(getTradeHistory());
        onClose();
      }
      if (isManualTrade) {
        onUpdateSuccess();
        dispatch(stopTradeLoading());
        onClose();
      }
    }
  };

  const handleCancelTrade = async () => {
    try {
      const res = await dispatch(cancelTrade({ tradeId: trade._id, isManualTrade }));
      enqueueSnackbar(res.message);
      if (closeFromPortfolio && !isManualTrade) {
        dispatch(getUserPortfolio());
        onClose();
      }
      if (closeFromTrade && !isManualTrade) {
        dispatch(getTradeHistory());
        onClose();
      }
      if (isManualTrade) {
        onUpdateSuccess();
        onClose();
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const handleModifyOrder = async (data) => {
    try {
      dispatch(startTradeLoading());
      const response = await axiosInstance.put(`/trade/modify/limit/${trade._id}`, data);
      enqueueSnackbar(response.data.message);
      if (isManualTrade) {
        onUpdateSuccess();
        dispatch(stopTradeLoading());
        onClose();
      } else {
        dispatch(getTradeHistory());
        onClose();
      }
    } catch (err) {
      dispatch(stopTradeLoading());
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Wrapper
      open={isOpen}
      onClose={onClose}
      children={
        <Box width={{ xs: '100%', sm: '350px' }} marginX={'auto'}>
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" textAlign="center" pb={2}>
            {modifyScriptName(trade.script.tradingsymbol)}
          </Typography>
          {user.role.rolename === 'admin' && trade.type === 'OPEN' && (
            <Box py={2}>
              <Typography textAlign={'center'}>
                {trade.side === 'BUY' ? 'Buy' : 'Sell'} price:{' '}
                {trade.side === 'BUY' ? fNumber(trade.buy) : fNumber(trade.sell)}
              </Typography>
            </Box>
          )}
          <FormProvider methods={methods}>
            {trade.type === 'PENDING' && (
              <RHFToggleButtonGroup
                name="tradeAction"
                label="Select Action"
                color="primary"
                defaultValue={selectedOption}
                sx={{ width: '100%', mb: 2 }}
                options={[
                  { label: 'Cancel', value: 'cancel' },
                  { label: 'Modify', value: 'modify' },
                ]}
              />
            )}

            <Box sx={{ display: 'grid', gap: 2 }}>
              {(trade.type === 'OPEN' || (trade.type === 'PENDING' && selectedOption === 'modify')) && (
                <RHFTextField
                  name="quantity"
                  label="Quantity"
                  type="number"
                  step={['COMEX', 'NSEIX', 'WINDEX'].includes(trade?.script?.exchange?.name) ? '0.10' : '1'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            const step = ['COMEX', 'NSEIX', 'WINDEX'].includes(trade?.script?.exchange?.name) ? 0.1 : 1;
                            const currentValue = Number((methods.getValues().quantity || 0).toFixed(2));
                            methods.setValue('quantity', Number((currentValue - step).toFixed(2)));
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            const step = ['COMEX', 'NSEIX', 'WINDEX'].includes(trade?.script?.exchange?.name) ? 0.1 : 1;
                            const currentValue = Number((methods.getValues().quantity || 0).toFixed(2));
                            methods.setValue('quantity', Number((currentValue + step).toFixed(2)));
                          }}
                        >
                          <Add />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              {user.role.rolename === 'admin' && trade.type === 'OPEN' && (
                // <Controller
                //   name="useCustomPrice"
                //   control={methods.control}
                //   render={({ field }) => (
                //     <FormControlLabel control={<Checkbox {...field} />} label="Close with Custom Price" />
                //   )}
                // />
                <RHFCheckbox name={'useCustomPrice'} label="Close with Custom Price" />
              )}

              {(selectedOption === 'modify' || useCustomPrice) && (
                <RHFTextField name="price" label="Price" type="number" />
              )}
            </Box>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {trade.type === 'OPEN' && (
                <Grid item xs={12}>
                  <LoadingButton
                    variant="contained"
                    onClick={handleSubmit(handleCloseTrade)}
                    fullWidth
                    size="large"
                    color="error"
                    loading={isLoading}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Close Trade
                  </LoadingButton>
                </Grid>
              )}
              {trade.type === 'PENDING' && (
                <>
                  {selectedOption === 'cancel' && (
                    <Grid item xs={12}>
                      <LoadingButton
                        variant="contained"
                        onClick={handleCancelTrade}
                        fullWidth
                        size="large"
                        color="error"
                        loading={isLoading}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Cancel Order
                      </LoadingButton>
                    </Grid>
                  )}
                  {selectedOption === 'modify' && (
                    <Grid item xs={12}>
                      <LoadingButton
                        variant="contained"
                        onClick={handleSubmit(handleModifyOrder)}
                        fullWidth
                        size="large"
                        color="primary"
                        loading={isLoading}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Modify Order
                      </LoadingButton>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </FormProvider>
        </Box>
      }
    />
  );
};

CloseTradeModal.propTypes = {
  trade: PropTypes.object,
  isOpen: PropTypes.bool,
  closeFromPortfolio: PropTypes.bool,
  closeFromTrade: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateSuccess: PropTypes.func,
  isManualTrade: PropTypes.bool,
};

export default CloseTradeModal;
