/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect } from 'react';
// import {
//   TextField,
//   Grid,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   Typography,
//   Button,
//   Box,
//   Card,
//   CardContent,
// } from '@mui/material';
// import { useSelector } from 'react-redux';
// import { LoadingButton } from '@mui/lab';
// import { ExpandMore } from '@mui/icons-material';
// import { useNavigate } from 'react-router';
// import { PATH_DASHBOARD } from '../../../../routes/paths';
// import axiosInstance from '../../../../utils/axios';
// import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
// import Iconify from '../../../../components/Iconify';
// import { dispatch } from '../../../../redux/store';
// import {
//   addToWatchListSuccess,
//   getInstrumentList,
//   getWatchList,
//   startTradeLoading,
// } from '../../../../redux/slices/trades';
// import useAuth from '../../../../hooks/useAuth';
// import modifyScriptName from '../../../../utils/modifyScriptName';

// const segements = ['NSE', 'MCX', 'COMEX', 'CRYTPO', 'FOREX'];

// function AddToWatchlist() {
//   const [searchText, setSearchText] = useState('');
//   // const [scripts, setScripts] = useState([]);
//   const [filteredScripts, setFilteredScripts] = useState([]);
//   // const watchlist = useSelector((state) => state?.trade.watchlist);
//   const { isLoading, watchlist, instrumentData } = useSelector((state) => state?.trade);
//   const [selectedExchange, setSelectedExchange] = useState(null);
//   const { user } = useAuth();
//   const navigate = useNavigate();
//   const scripts = instrumentData?.docs || [];
//   const userSegements = segements.filter((segement) => {
//     if (user[segement]) return user[segement].allow;
//     return false;
//   });

//   useEffect(() => {
//     // const fetchScripts = async () => {
//     //   try {
//     //     const { data } = await axiosInstance('/script/all');
//     //     console.log('DATA', data);
//     //     setScripts(data.data.docs);
//     //   } catch (error) {
//     //     console.error('Error fetching scripts:', error);
//     //   }
//     // };
//     // fetchScripts();
//     dispatch(getInstrumentList());
//     dispatch(getWatchList());
//   }, []);

//   useEffect(() => {
//     filterScripts();
//   }, [searchText, watchlist, scripts]);

//   const filterScripts = () => {
//     const allScripts = watchlist ? [].concat(...Object.values(watchlist)) : [];
//     console.log('ALL SCRIPTS', allScripts);
//     const filtered = searchText
//       ? scripts.filter(
//           (script) =>
//             script.tradingsymbol.toLowerCase().includes(searchText.toLowerCase()) &&
//             !allScripts.some((s) => s?._id === script._id) &&
//             userSegements.includes(script?.exchange?.name)
//         )
//       : // .filter((script) => {
//         //   // Check if the script is not in the watchlist for the selected exchange
//         //   return !allScripts.some((s) => s?._id === script._id);
//         // })
//         scripts.filter((script) => {
//           // Check if the script is not in the watchlist for the selected exchange
//           return !allScripts.some((s) => s?._id === script._id) && userSegements.includes(script?.exchange?.name);
//         });
//     setFilteredScripts(filtered);
//   };

//   const handleSearchChange = (event) => {
//     setSearchText(event.target.value);
//   };

//   const handleBackclick = () => {
//     navigate(PATH_DASHBOARD.bankerRole.watchlist.root);
//   };
//   const handleExchangeClick = (exchange) => {
//     setSelectedExchange(selectedExchange === exchange ? null : exchange);
//   };

//   const handleAddToWatchlist = (script) => {
//     // Deserialize watchlist from local storage
//     dispatch(startTradeLoading());
//     const updatedWatchlist = JSON.parse(localStorage.getItem('watchlist')) || {};

//     // If no watchlist exists in local storage for the exchange, create an empty array
//     updatedWatchlist[script.exchange.name] = updatedWatchlist[script.exchange.name] || [];

//     // Insert the script into the exchange's watchlist
//     updatedWatchlist[script.exchange.name].push(script);

//     // Update watchlist state and local storage
//     localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
//     dispatch(addToWatchListSuccess(updatedWatchlist));

//     // Remove the added script from filteredScripts
//     const updatedFilteredScripts = filteredScripts.filter((s) => s._id !== script._id);
//     setFilteredScripts(updatedFilteredScripts);
//   };

//   // console.log('FILTER ', scripts);
//   console.log('LOADING', isLoading);
//   if (isLoading) return <div>...Loading</div>;

//   return (
//     <Box>
//       <Button
//         startIcon={<Iconify icon={'eva:arrow-back-outline'} />}
//         variant="outlined"
//         sx={{ mb: 2, ml: 1 }}
//         onClick={handleBackclick}
//       >
//         Back
//       </Button>
//       <Grid container spacing={2} sx={{ px: 1 }}>
//         <Grid item xs={12}>
//           <TextField fullWidth label="Search Scripts" value={searchText} onChange={handleSearchChange} />
//         </Grid>
//         <Grid item xs={12}>
//           {searchText ? (
//             <List>
//               {filteredScripts.map((script) => (
//                 <ListItem key={script._id} button>
//                   <ListItemText primary={`${modifyScriptName(script.tradingsymbol)}`} />
//                   <ListItemSecondaryAction>
//                     <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//                       <ListItemText primary={`${script.exchange.name}`} sx={{ mr: 2 }} />
//                       <LoadingButton
//                         variant="contained"
//                         onClick={() => handleAddToWatchlist(script)}
//                         loading={isLoading}
//                       >
//                         Add
//                       </LoadingButton>
//                     </Box>
//                   </ListItemSecondaryAction>
//                 </ListItem>
//               ))}
//             </List>
//           ) : (
//             <>
//               {Object.entries(
//                 filteredScripts.reduce((acc, script) => {
//                   const exchange = script.exchange.name || 'Unknown';
//                   if (!acc[exchange]) {
//                     acc[exchange] = [];
//                   }
//                   acc[exchange].push(script);
//                   return acc;
//                 }, {})
//               ).map(([exchange, scripts]) => (
//                 <React.Fragment key={exchange}>
//                   <Card
//                     sx={{ cursor: 'pointer', padding: 1, marginBottom: 2 }}
//                     onClick={() => handleExchangeClick(exchange)}
//                   >
//                     <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
//                       <Typography variant="h6">{exchange}</Typography>
//                       <ExpandMore sx={{ marginLeft: 'auto' }} />
//                     </CardContent>
//                   </Card>
//                   {selectedExchange === exchange && (
//                     <List>
//                       {scripts.map((script) => (
//                         <ListItem
//                           key={script._id}
//                           button
//                           sx={{ display: 'flex', justifyContent: 'space-between' }}
//                           onClick={() => handleAddToWatchlist(script)}
//                         >
//                           <ListItemText primary={`${modifyScriptName(script.tradingsymbol)}`} />
//                           <ListItemSecondaryAction>
//                             <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//                               <ListItemText primary={`${script.exchange.name}`} sx={{ mr: 2 }} />
//                               <LoadingButton
//                                 variant="contained"
//                                 onClick={() => handleAddToWatchlist(script)}
//                                 loading={isLoading}
//                               >
//                                 Add
//                               </LoadingButton>
//                             </Box>
//                           </ListItemSecondaryAction>
//                         </ListItem>
//                       ))}
//                     </List>
//                   )}
//                 </React.Fragment>
//               ))}
//             </>
//           )}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }

// export default AddToWatchlist;

import React, { useState, useEffect } from 'react';
import { TextField, Grid, Typography, Button, Box, Card, CardContent } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router';
// import { PATH_DASHBOARD } from '../../../../routes/paths';
import Iconify from '../../../../components/Iconify';
import { dispatch } from '../../../../redux/store';
import {
  addToWatchListSuccess,
  getInstrumentList,
  getWatchList,
  startTradeLoading,
} from '../../../../redux/slices/trades';
import useAuth from '../../../../hooks/useAuth';
import modifyScriptName from '../../../../utils/modifyScriptName';

const segements = ['NSE', 'MCX', 'COMEX', 'WINDEX', 'NSEIX'];

function AddToWatchlist() {
  const [searchText, setSearchText] = useState('');
  const [filteredScripts, setFilteredScripts] = useState([]);
  const tradeData = useSelector((state) => state?.trade);
  const { watchlist, instrumentData } = tradeData;
  const [selectedExchange, setSelectedExchange] = useState(null);
  const { user } = useAuth();
  console.log('user', user);
  const navigate = useNavigate();
  const scripts = instrumentData?.docs || [];
  const userSegements = segements.filter((segement) => {
    if (user[segement]) return user[segement].allow;
    return false;
  });

  useEffect(() => {
    dispatch(getInstrumentList());
    dispatch(getWatchList(false));
  }, []);

  useEffect(() => {
    filterScripts();
  }, [searchText, watchlist, instrumentData]);

  const filterScripts = () => {
    const allScripts = watchlist ? [].concat(...Object.values(watchlist)) : [];

    const filtered = searchText
      ? scripts.filter(
          (script) =>
            script.tradingsymbol.toLowerCase().includes(searchText.toLowerCase()) &&
            !allScripts.some((s) => s?._id === script._id) &&
            userSegements.includes(script?.exchange?.name)
        )
      : scripts.filter(
          (script) => !allScripts.some((s) => s?._id === script._id) && userSegements.includes(script?.exchange?.name)
        );
    setFilteredScripts(filtered);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleBackclick = () => {
    // navigate(PATH_DASHBOARD.bankerRole.watchlist.root);
    navigate(-1);
  };

  const handleExchangeClick = (exchange) => {
    setSelectedExchange(selectedExchange === exchange ? null : exchange);
  };

  // const handleAddToWatchlist = (script) => {
  //   console.log('script', script);
  //   dispatch(startTradeLoading());
  //   const updatedWatchlist = JSON.parse(localStorage.getItem('watchlist')) || {};
  //   updatedWatchlist[script.exchange.name] = updatedWatchlist[script.exchange.name] || [];
  //   updatedWatchlist[script.exchange.name].push(script);
  //   localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
  //   dispatch(addToWatchListSuccess(updatedWatchlist));
  //   const updatedFilteredScripts = filteredScripts.filter((s) => s._id !== script._id);
  //   setFilteredScripts(updatedFilteredScripts);
  // };
  const handleAddToWatchlist = (script) => {
    dispatch(startTradeLoading());

    // Get the current watchlist from local storage
    const updatedWatchlist = JSON.parse(localStorage.getItem('watchlist')) || {};

    // Initialize the exchange watchlist if it doesn't exist
    updatedWatchlist[script.exchange.name] = updatedWatchlist[script.exchange.name] || [];

    // Create a set of current script IDs for the exchange
    const scriptIds = new Set(updatedWatchlist[script.exchange.name].map((s) => s._id));

    // Add the script if its _id is not already in the set
    if (!scriptIds.has(script._id)) {
      updatedWatchlist[script.exchange.name].push(script);
      localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
      dispatch(addToWatchListSuccess(updatedWatchlist));
    }

    // Update the filtered scripts
    const updatedFilteredScripts = filteredScripts.filter((s) => s._id !== script._id);
    setFilteredScripts(updatedFilteredScripts);
  };

  const renderRow = ({ index, style }) => {
    const script = filteredScripts[index];
    return (
      <Grid
        container
        style={style}
        key={script._id}
        alignItems="center"
        spacing={2}
        sx={{ cursor: 'pointer', ':hover': { backgroundColor: 'action.selected' } }}
        className="list-item"
        onClick={() => handleAddToWatchlist(script)}
      >
        <Grid item xs={8}>
          <Typography>{modifyScriptName(script.tradingsymbol)}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography align="center">{script.exchange.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Box textAlign="right">
            <LoadingButton variant="contained" onClick={() => handleAddToWatchlist(script)}>
              Add
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <Button
        startIcon={<Iconify icon={'eva:arrow-back-outline'} />}
        variant="outlined"
        sx={{ mb: 2, ml: 1 }}
        onClick={handleBackclick}
      >
        Back
      </Button>
      <Grid container spacing={2} sx={{ px: 1 }}>
        <Grid item xs={12}>
          <TextField fullWidth label="Search Scripts" value={searchText} onChange={handleSearchChange} />
        </Grid>
        <Grid item xs={12}>
          {tradeData.isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                loading
                size="large"
                sx={{
                  '.MuiCircularProgress-root': {
                    width: '50px !important',
                    height: '50px !important',
                    color: 'primary.main',
                  },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'primary.main',
                }}
              />
            </Box>
          )}
          {searchText ? (
            <List height={400} itemCount={filteredScripts.length} itemSize={50} width="100%">
              {renderRow}
            </List>
          ) : (
            <>
              {Object.entries(
                filteredScripts.reduce((acc, script) => {
                  console.log('Scripts', script);
                  const exchange = script.exchange.name || 'Unknown';
                  if (!acc[exchange]) {
                    acc[exchange] = [];
                  }
                  acc[exchange].push(script);
                  return acc;
                }, {})
              ).map(([exchange, scripts]) => (
                <React.Fragment key={exchange}>
                  <Card
                    sx={{ cursor: 'pointer', padding: 1, marginBottom: 2 }}
                    onClick={() => handleExchangeClick(exchange)}
                  >
                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="h6">{exchange}</Typography>
                      <ExpandMore sx={{ marginLeft: 'auto' }} />
                    </CardContent>
                  </Card>
                  {selectedExchange === exchange && (
                    <List height={400} itemCount={scripts.length} itemSize={50} width="100%">
                      {({ index, style }) => {
                        const script = scripts[index];
                        return (
                          <Grid
                            container
                            style={style}
                            key={script._id}
                            alignItems="center"
                            spacing={2}
                            sx={{ cursor: 'pointer', ':hover': { backgroundColor: 'action.selected' } }}
                            className="list-item"
                            onClick={() => handleAddToWatchlist(script)}
                          >
                            <Grid item xs={8}>
                              <Typography>{modifyScriptName(script.tradingsymbol)}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography align="center">{script.exchange.name}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Box textAlign="right">
                                <LoadingButton variant="contained">Add</LoadingButton>
                              </Box>
                            </Grid>
                          </Grid>
                        );
                      }}
                    </List>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddToWatchlist;
