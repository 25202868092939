import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import { getQuoteSuccess, getTradeHistory, getUserPortfolio } from '../redux/slices/trades';
import { operatorActions } from '../redux/slices/operator';
import useAuth from '../hooks/useAuth';

const TradeContext = createContext();

const LOCAL_SERVER = process.env.REACT_APP_SOCKER_URL;

const initialState = {
  socket: null,
};

TradeProvider.propTypes = {
  children: PropTypes.node,
};

const handlers = {
  SET_SOCKET: (state, action) => ({ ...state, socket: action.payload }),
  CLEAR_SOCKET: (state) => ({ ...state, socket: null }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

function TradeProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { logout, initialize } = useAuth();
  const dispatchMessage = useDispatch();
  const reconnectAttempts = useRef(0);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    const initializeSocket = () => {
      const socket = io(LOCAL_SERVER, {
        query: { token },
        reconnectionAttempts: 5, // Limit reconnection attempts
      });

      dispatch({ type: 'SET_SOCKET', payload: socket });

      socket.on('connect', () => {
        console.log('Socket.IO connected:', socket.id);
        reconnectAttempts.current = 0; // Reset reconnect attempts on successful connection
      });

      socket.on('disconnect', (reason) => {
        console.log('Socket.IO disconnected:', reason);
        if (reason === 'io server disconnect') {
          // Manually disconnected by the server, no auto-reconnect
          socket.connect();
        }
      });

      socket.on('connect_error', (error) => {
        console.error('Socket.IO connection error:', error);
        reconnectAttempts.current += 1;
        if (reconnectAttempts.current >= 5) {
          console.log('Max reconnect attempts reached.');
        }
      });

      return socket;
    };

    const socket = initializeSocket();

    // Cleanup on unmount
    return () => {
      socket.disconnect();
      dispatch({ type: 'CLEAR_SOCKET' });
    };
  }, []);

  useEffect(() => {
    const { socket } = state;

    if (!socket) return;

    // Example: Listening for specific events
    socket.on('priceUpdate', (data) => {
      // eslint-disable-next-line no-unused-vars
      const { exchange, ...rest } = data;
      dispatchMessage(getQuoteSuccess(rest));
    });

    socket.on('limit_executed', () => {
      dispatchMessage(getUserPortfolio());
      dispatchMessage(getTradeHistory());
    });

    socket.on('refresh_trade', () => {
      dispatchMessage(getUserPortfolio());
      dispatchMessage(getTradeHistory());
    });

    socket.on('active_positions', (data) => {
      dispatchMessage(operatorActions.getScriptWiseTradeSuccess(data));
    });

    socket.on('scriptwise_active_clients', (data) => {
      dispatchMessage(operatorActions.getcurrentScriptClientTradeSuccess(data));
    });

    socket.on('dashboard_subscribe', (data) => {
      dispatchMessage(operatorActions.getUserBalanceSummarySuccess(data?.data));
      dispatchMessage(operatorActions.getUserMoneyDetailSuccess(data?.summary));
    });

    socket.on('session_replaced', () => {
      logout();
    });
    socket.on('refresh_profile', () => {
      initialize();
    });

    socket.on('auth_error', () => {
      logout();
    });

    socket.on('ping', () => {
      socket.emit('pong'); // Respond to ping
    });

    // Cleanup event listeners
    // eslint-disable-next-line consistent-return
    return () => {
      socket.off('subscribe');
      socket.off('limit_executed');
      socket.off('refresh_trade');
      socket.off('active_positions');
      socket.off('scriptwise_active_clients');
      socket.off('dashboard_subscribe');
      socket.off('session_replaced');
      socket.off('refresh_profile');
      socket.off('auth_error');
      socket.off('ping');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.socket]);

  return <TradeContext.Provider value={state}>{children}</TradeContext.Provider>;
}

export { TradeContext, TradeProvider };
