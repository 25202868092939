import * as Yup from 'yup';

const numberValidtor = (errorMessage = 'Value is required') =>
  Yup.number().required(`${errorMessage}`).typeError('Value must be a number').min(0, 'Value must be a greator than 0');

const commodityValidator = Yup.object().shape({
  perTradeLimit: numberValidtor('Per Trade Limit is required'),
  perScriptLimit: numberValidtor('Per Script Limit is required'),
  brokerageValue: numberValidtor('Brokerage is required'),
  intradayExposure: numberValidtor('Intraday Exposure is required'),
  holdingExposure: numberValidtor('Holding Exposure is required'),
  refundBrokerage: numberValidtor('refund Brokerage is required').test(
    'refundBrokerage',
    'refund brokearge must be less than brokerage',
    function compareRefundBrokerage(value) {
      const { brokerageValue } = this.parent;
      return value <= brokerageValue;
    }
  ),
});

const equityLotValidator = Yup.object().shape({
  min: numberValidtor('Min is required'),
  perTradeLimit: numberValidtor('Per Trade Limit is required'),
  max: numberValidtor('Max is required').test('max', 'Max must be greater than Min', function compareMinMax(value) {
    const { min } = this.parent;
    return value >= min;
  }),
});

// Validation schema for Admin
const adminSchema = Yup.object().shape({
  NSE: Yup.object().when('allowEquity', {
    is: true,
    then: Yup.object().shape({
      blockedScripts: Yup.array().of(Yup.object().required('Blocked script is required')).nullable(),

      brokerageType: Yup.string()
        .oneOf(['percentage', 'crorebase'], 'Invalid Brokerage Type')
        .when('profitLossType', {
          is: 'adminwise',
          then: Yup.string().required('Equity Brokerage Type is required'),
          otherwise: Yup.string().notRequired(),
        }),

      brokerageValue: Yup.number().when('profitLossType', {
        is: 'adminwise',
        then: Yup.number().when('brokerageType', {
          is: 'percentage',
          then: Yup.number()
            .required('Equity Brokerage Value is required')
            .min(0, 'Equity Brokerage Value must be at least 0')
            .max(100, 'Equity Brokerage Value must be at most 100'),
          otherwise: Yup.number().required('Equity Brokerage Value is required'), // Number without percentage condition
        }),
        otherwise: Yup.number().notRequired(), // Not adminwise, so no validation required
      }),

      superAdminBrokerageValue: Yup.number().notRequired(),

      brokerBrokerageValue: Yup.number().notRequired(),
    }),
  }),

  MCX: Yup.object().when('allowMCX', {
    is: true,
    then: Yup.object().shape({
      blockedScripts: Yup.array().of(Yup.object().required('Blocked script is required')),

      brokerageType: Yup.string()
        .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
        .when('profitLossType', {
          is: 'adminwise',
          then: Yup.string().required('Brokerage Type is required'),
          otherwise: Yup.string().notRequired(),
        }),

      brokerageValue: Yup.number().when('brokerageType', {
        is: 'percentage',
        then: numberValidtor('Brokerage Value is required'),
        otherwise: Yup.number().notRequired(),
      }),

      // Additional fields for lotwise brokerage
      brokerage: Yup.object().when('brokerageType', {
        is: 'lotwise',
        then: Yup.object().shape({
          metal: Yup.number().notRequired(),
          miniMetal: Yup.number().notRequired(),
          baseMetal: Yup.number().notRequired(),
          energy: Yup.number().notRequired(),
        }),
      }),
    }),
  }),

  COMEX: Yup.object().when('allowComex', {
    is: true,
    then: Yup.object().shape({
      blockedScripts: Yup.array().of(Yup.string().required('Blocked script is required')),

      brokerageType: Yup.string()
        .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
        .required('Comex Brokerage Type is required'),

      brokerageValue: Yup.number().when('brokerageType', {
        is: 'percentage',
        then: numberValidtor('Brokerage value for COMEX is required')
          .min(0, 'Value must be at least 0')
          .max(100, 'Value must be at most 100'),
        otherwise: Yup.number().notRequired(),
      }),

      brokerage: Yup.object().when('brokerageType', {
        is: 'lotwise',
        then: Yup.object().shape({
          gold: numberValidtor('Gold Brokerage is required'),
          silver: numberValidtor('Silver Brokerage is required'),
          copper: numberValidtor('Copper Brokerage is required'),
          crudeoil: numberValidtor('Crude Oil Brokerage is required'),
          naturalgas: numberValidtor('Natural Gas Brokerage is required'),
        }),
      }),
    }),
  }),
  NSEIX: Yup.object().when('allowNSEIX', {
    is: true,
    then: Yup.object().shape({
      blockedScripts: Yup.array().of(Yup.string().required('Blocked script is required')),

      brokerageType: Yup.string()
        .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
        .required('Comex Brokerage Type is required'),

      brokerageValue: Yup.number().when('brokerageType', {
        is: 'percentage',
        then: numberValidtor('NSEIX brokerage value is required')
          .min(0, 'Value must be at least 0')
          .max(100, 'Value must be at most 100'),
        otherwise: Yup.number().notRequired(),
      }),

      brokerage: Yup.object().when('brokerageType', {
        is: 'lotwise',
        then: Yup.object().shape({
          giftNifty: numberValidtor('Gift Nifty Brokerage is required'),
        }),
      }),
    }),
  }),
  WINDEX: Yup.object().when('allowWINDEX', {
    is: true,
    then: Yup.object().shape({
      INR: Yup.object().shape({
        blockedScripts: Yup.array().of(Yup.string().required('Blocked script is required')),

        brokerageType: Yup.string()
          .oneOf(['percentage', 'crorebase'], 'Invalid Brokerage Type')
          .required('WINDEX Brokerage Type is required'),

        brokerageValue: Yup.number().when('profitLossType', {
          is: (profitLossType) => profitLossType === 'adminwise',
          then: Yup.number().when('brokerageType', {
            is: 'percentage',
            then: Yup.number()
              .required('Equity Brokerage Value is required')
              .min(0, 'Equity Brokerage Value must be at least 0')
              .max(100, 'Equity Brokerage Value must be at most 100'),
            otherwise: Yup.number().required('Equity Brokerage Value is required'), // Number without percentage condition
          }),
          otherwise: Yup.number().notRequired(), // Not adminwise, so no validation required
        }),
      }),
      USD: Yup.object().shape({
        blockedScripts: Yup.array().of(Yup.string().required('Blocked script is required')),

        brokerageType: Yup.string()
          .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
          .required('WINDEX Brokerage Type is required'),

        brokerageValue: Yup.number().when('brokerageType', {
          is: 'percentage',
          then: numberValidtor('WINDEX brokerage value is required')
            .min(0, 'Value must be at least 0')
            .max(100, 'Value must be at most 100'),
          otherwise: Yup.number().notRequired(),
        }),

        brokerage: Yup.object().when('brokerageType', {
          is: 'lotwise',
          then: Yup.object().shape({
            nasdaq: numberValidtor('Nasdaq Brokerage is required'),
            dowjones: numberValidtor('Dowjones Brokerage is required'),
            spx: numberValidtor('S&P Brokerage is required'),
            default: numberValidtor('Default Brokerage is required'),
          }),
        }),
      }),
    }),
  }),
});

// Validation schema for Broker
const brokerSchema = (profitLossType) => {
  console.log('PPPPPPPPPPPPPPP', profitLossType);
  return Yup.object().shape({
    NSE: Yup.object().when('allowEquity', {
      is: true,
      then: Yup.object().shape({
        blockedScripts: Yup.array().of(Yup.object().required('Blocked script is required')).nullable(),

        brokerageType: Yup.string()
          .oneOf(['percentage', 'crorebase'], 'Invalid Brokerage Type')
          .when('allowEquity', {
            is: true,
            then: Yup.string().required('Equity Brokerage Type is required'),
            otherwise: Yup.string().notRequired(),
          }),

        brokerageValue: Yup.number()
          .required('WINDEX Brokerage Value is required')
          .typeError('WINDEX Brokerage Value must be a number')
          .when('brokerageType', {
            is: (brokerageType) => brokerageType === 'percentage',
            then: numberValidtor('WINDEX Brokerage Value is required')
              .min(0, 'WINDEX Brokerage Value must be at least 0')
              .max(100, 'WINDEX Brokerage Value must be at most 100'),
            otherwise: Yup.number().positive('WINDEX Brokerage Value must be positive'),
          }),

        superAdminBrokerageValue: Yup.number().when('brokerageType', {
          is: (brokerageType) => profitLossType === 'brokerwise' && brokerageType === 'percentage',
          then: numberValidtor('Super Admin Brokerage is required')
            .min(0, 'Super Admin Brokerage must be at least 0')
            .max(100, 'Super Admin Brokerage must be at most 100'),
          otherwise: Yup.number().notRequired(),
        }),

        brokerBrokerageValue: Yup.number()
          .when('brokerageType', {
            is: (brokerageType) => profitLossType === 'brokerwise' && brokerageType === 'percentage',
            then: numberValidtor('Broker Brokerage is required')
              .min(0, 'Broker Brokerage must be at least 0')
              .max(100, 'Broker Brokerage must be at most 100'),
            otherwise: Yup.number().notRequired(),
          })
          .test(
            'sum-check',
            'The sum of Super Admin Brokerage and Broker Brokerage must equal 100',
            function handlesum() {
              const { brokerageType, superAdminBrokerageValue, brokerBrokerageValue, brokerageValue } =
                this.parent || {};
              if (
                brokerageType === 'percentage' &&
                profitLossType === 'brokerwise' &&
                superAdminBrokerageValue != null &&
                brokerBrokerageValue != null
              ) {
                const total = (superAdminBrokerageValue || 0) + (brokerBrokerageValue || 0) + (brokerageValue || 0);
                return total === 100;
              }
              return true;
            }
          ),
      }),
    }),

    MCX: Yup.object().when('allowMCX', {
      is: true,
      then: Yup.object().shape({
        blockedScripts: Yup.array().of(Yup.object().required('Blocked script is required')),

        brokerageType: Yup.string()
          .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
          .when('allowMCX', {
            is: true,
            then: Yup.string().required('MCX Brokerage Type is required'),
            otherwise: Yup.string().notRequired(),
          }),

        brokerageValue: Yup.number().when('brokerageType', {
          is: 'percentage',
          then: numberValidtor('Brokerage Value is required')
            .min(0, 'Brokerage must be at least 0')
            .max(100, 'Brokerage must be at most 100'),
          otherwise: Yup.number().notRequired(),
        }),

        brokerage: Yup.object().when('brokerageType', {
          is: 'lotwise',
          then: Yup.object().shape({
            metal: numberValidtor('Metal Brokerage is required'),
            miniMetal: numberValidtor('Mini Metal Brokerage is required'),
            baseMetal: numberValidtor('Base Metal Brokerage is required'),
            energy: numberValidtor('Energy Brokerage is required'),
          }),
        }),

        superAdmin: Yup.object().when('brokerageType', {
          is: (brokerageType) => profitLossType === 'brokerwise' && brokerageType === 'percentage',
          then: () =>
            Yup.object().shape({
              brokerageValue: numberValidtor('Brokerage Value is required')
                .min(0, 'Super Admin Brokerage must be at least 0')
                .max(100, 'Super Admin Brokerage must be at most 100'), // Required when brokerageType is percentage and profitLossType is brokerwise
            }),
          otherwise: () =>
            Yup.object().when('brokerageType', {
              is: (brokerageType, profitLossType) => profitLossType === 'brokerwise' && brokerageType === 'lotwise',
              then: () =>
                Yup.object().shape({
                  brokerage: Yup.object().shape({
                    metal: numberValidtor('Super Admin Metal Brokerage is required'),
                    miniMetal: numberValidtor('Super Admin Mini Metal Brokerage is required'),
                    baseMetal: numberValidtor('Super Admin Base Metal Brokerage is required'),
                    energy: numberValidtor('Super Admin Energy Brokerage is required'),
                  }),
                }),
              otherwise: () => Yup.object().notRequired(), // If brokerageType is neither 'percentage' nor 'lotwise', or profitLossType is not 'brokerwise'
            }),
        }),

        broker: Yup.object().when('brokerageType', {
          is: (brokerageType) => brokerageType === 'percentage' && profitLossType === 'brokerwise',
          then: Yup.object().shape({
            brokerageValue: numberValidtor('Broker Brokerage Value is required')
              .min(0, 'Broker Brokerage must be at least 0')
              .max(100, 'Broker Brokerage must be at most 100'),
          }),
          otherwise: Yup.object().notRequired(),
        }),
      }),
    }),
    COMEX: Yup.object().when('allowComex', {
      is: true,
      then: Yup.object().shape({
        blockedScripts: Yup.array().of(Yup.object().required('Blocked script is required')),

        brokerageType: Yup.string()
          .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
          .when('allowComex', {
            is: true,
            then: Yup.string().required('COMEX Brokerage Type is required'),
            otherwise: Yup.string().notRequired(),
          }),

        brokerageValue: Yup.number().when('brokerageType', {
          is: 'percentage',
          then: numberValidtor('Brokerage Value is required')
            .min(0, 'Brokerage must be at least 0')
            .max(100, 'Brokerage must be at most 100'),
          otherwise: Yup.number().notRequired(),
        }),

        brokerage: Yup.object().when('brokerageType', {
          is: 'lotwise',
          then: Yup.object().shape({
            gold: numberValidtor('Gold Brokerage is required'),
            silver: numberValidtor('Silver Brokerage is required'),
            copper: numberValidtor('Copper Brokerage is required'),
            crudeoil: numberValidtor('Crude Oil Brokerage is required'),
            naturalgas: numberValidtor('Natural Gas Brokerage is required'),
          }),
        }),

        superAdmin: Yup.object().when('brokerageType', {
          is: (brokerageType) => profitLossType === 'brokerwise' && brokerageType === 'percentage',
          then: () =>
            Yup.object().shape({
              brokerageValue: numberValidtor('Brokerage Value is required')
                .min(0, 'Super Admin Brokerage must be at least 0')
                .max(100, 'Super Admin Brokerage must be at most 100'), // Required when brokerageType is percentage and profitLossType is brokerwise
            }),
          otherwise: () =>
            Yup.object().when('brokerageType', {
              is: (brokerageType, profitLossType) => profitLossType === 'brokerwise' && brokerageType === 'lotwise',
              then: () =>
                Yup.object().shape({
                  brokerage: Yup.object().shape({
                    gold: numberValidtor('Gold Brokerage is required'),
                    silver: numberValidtor('Silver Brokerage is required'),
                    copper: numberValidtor('Copper Brokerage is required'),
                    crudeoil: numberValidtor('Crude Oil Brokerage is required'),
                    naturalgas: numberValidtor('Natural Gas Brokerage is required'),
                  }),
                }),
              otherwise: () => Yup.object().notRequired(), // If brokerageType is neither 'percentage' nor 'lotwise', or profitLossType is not 'brokerwise'
            }),
        }),

        broker: Yup.object().when('brokerageType', {
          is: (brokerageType) => brokerageType === 'percentage' && profitLossType === 'brokerwise',
          then: Yup.object().shape({
            brokerageValue: numberValidtor('Broker Brokerage Value is required')
              .min(0, 'Broker Brokerage must be at least 0')
              .max(100, 'Broker Brokerage must be at most 100'),
          }),
          otherwise: Yup.object().notRequired(),
        }),
      }),
    }),
    WINDEX: Yup.object().when('allowWINDEX', {
      is: true,
      then: Yup.object().shape({
        INR: Yup.object().shape({
          blockedScripts: Yup.array().of(Yup.object().required('Blocked script is required')),

          brokerageType: Yup.string()
            .oneOf(['percentage', 'crorebase'], 'Invalid Brokerage Type')
            .when('allowWINDEX', {
              is: true,
              then: Yup.string().required('COMEX Brokerage Type is required'),
              otherwise: Yup.string().notRequired(),
            }),

          brokerageValue: Yup.number()
            .required('Equity Brokerage Value is required')
            .typeError('Equity Brokerage Value must be a number')
            .when('brokerageType', {
              is: (brokerageType) => brokerageType === 'percentage',
              then: numberValidtor('Equity Brokerage Value is required')
                .min(0, 'Equity Brokerage Value must be at least 0')
                .max(100, 'Equity Brokerage Value must be at most 100'),
              otherwise: Yup.number().positive('Equity Brokerage Value must be positive'),
            }),

          superAdminBrokerageValue: Yup.number().when('brokerageType', {
            is: (brokerageType) => profitLossType === 'brokerwise' && brokerageType === 'percentage',
            then: numberValidtor('Super Admin Brokerage is required')
              .min(0, 'Super Admin Brokerage must be at least 0')
              .max(100, 'Super Admin Brokerage must be at most 100'),
            otherwise: Yup.number().notRequired(),
          }),

          brokerBrokerageValue: Yup.number()
            .when('brokerageType', {
              is: (brokerageType) => profitLossType === 'brokerwise' && brokerageType === 'percentage',
              then: numberValidtor('Broker Brokerage is required')
                .min(0, 'Broker Brokerage must be at least 0')
                .max(100, 'Broker Brokerage must be at most 100'),
              otherwise: Yup.number().notRequired(),
            })
            .test(
              'sum-check',
              'The sum of Super Admin Brokerage and Broker Brokerage must equal 100',
              function handlesum() {
                const { brokerageType, superAdminBrokerageValue, brokerBrokerageValue, brokerageValue } =
                  this.parent || {};
                if (
                  brokerageType === 'percentage' &&
                  profitLossType === 'brokerwise' &&
                  superAdminBrokerageValue != null &&
                  brokerBrokerageValue != null
                ) {
                  const total = (superAdminBrokerageValue || 0) + (brokerBrokerageValue || 0) + (brokerageValue || 0);
                  return total === 100;
                }
                return true;
              }
            ),
        }),
        USD: Yup.object().shape({
          blockedScripts: Yup.array().of(Yup.object().required('Blocked script is required')),

          brokerageType: Yup.string()
            .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
            .when('allowWINDEX', {
              is: true,
              then: Yup.string().required('COMEX Brokerage Type is required'),
              otherwise: Yup.string().notRequired(),
            }),

          brokerageValue: Yup.number().when('brokerageType', {
            is: 'percentage',
            then: numberValidtor('Brokerage Value is required')
              .min(0, 'Brokerage must be at least 0')
              .max(100, 'Brokerage must be at most 100'),
            otherwise: Yup.number().notRequired(),
          }),

          brokerage: Yup.object().when('brokerageType', {
            is: 'lotwise',
            then: Yup.object().shape({
              nasdaq: numberValidtor('Nasdaq Brokerage is required'),
              dowjones: numberValidtor('Dowjones Brokerage is required'),
              spx: numberValidtor('S&P Brokerage is required'),
              default: numberValidtor('Default Brokerage is required'),
            }),
          }),

          superAdmin: Yup.object().when('brokerageType', {
            is: (brokerageType) => profitLossType === 'brokerwise' && brokerageType === 'percentage',
            then: () =>
              Yup.object().shape({
                brokerageValue: numberValidtor('Brokerage Value is required')
                  .min(0, 'Super Admin Brokerage must be at least 0')
                  .max(100, 'Super Admin Brokerage must be at most 100'), // Required when brokerageType is percentage and profitLossType is brokerwise
              }),
            otherwise: () =>
              Yup.object().when('brokerageType', {
                is: (brokerageType, profitLossType) => profitLossType === 'brokerwise' && brokerageType === 'lotwise',
                then: () =>
                  Yup.object().shape({
                    brokerage: Yup.object().shape({
                      nasdaq: numberValidtor('Nasdaq Brokerage is required'),
                      dowjones: numberValidtor('Dowjones Brokerage is required'),
                      spx: numberValidtor('S&P Brokerage is required'),
                      default: numberValidtor('Default Brokerage is required'),
                    }),
                  }),
                otherwise: () => Yup.object().notRequired(), // If brokerageType is neither 'percentage' nor 'lotwise', or profitLossType is not 'brokerwise'
              }),
          }),

          broker: Yup.object().when('brokerageType', {
            is: (brokerageType) => brokerageType === 'percentage' && profitLossType === 'brokerwise',
            then: Yup.object().shape({
              brokerageValue: numberValidtor('Broker Brokerage Value is required')
                .min(0, 'Broker Brokerage must be at least 0')
                .max(100, 'Broker Brokerage must be at most 100'),
            }),
            otherwise: Yup.object().notRequired(),
          }),
        }),
      }),
    }),
    NSEIX: Yup.object().when('allowNSEIX', {
      is: true,
      then: Yup.object().shape({
        blockedScripts: Yup.array().of(Yup.object().required('Blocked script is required')),

        brokerageType: Yup.string()
          .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
          .when('allowNSEIX', {
            is: true,
            then: Yup.string().required('COMEX Brokerage Type is required'),
            otherwise: Yup.string().notRequired(),
          }),

        brokerageValue: Yup.number().when('brokerageType', {
          is: 'percentage',
          then: numberValidtor('Brokerage Value is required')
            .min(0, 'Brokerage must be at least 0')
            .max(100, 'Brokerage must be at most 100'),
          otherwise: Yup.number().notRequired(),
        }),

        brokerage: Yup.object().when('brokerageType', {
          is: 'lotwise',
          then: Yup.object().shape({
            nasdaq: numberValidtor('Nasdaq Brokerage is required'),
            dowjones: numberValidtor('Dowjones Brokerage is required'),
            spx: numberValidtor('S&P Brokerage is required'),
            default: numberValidtor('Default Brokerage is required'),
          }),
        }),

        superAdmin: Yup.object().when('brokerageType', {
          is: (brokerageType) => profitLossType === 'brokerwise' && brokerageType === 'percentage',
          then: () =>
            Yup.object().shape({
              brokerageValue: numberValidtor('Brokerage Value is required')
                .min(0, 'Super Admin Brokerage must be at least 0')
                .max(100, 'Super Admin Brokerage must be at most 100'), // Required when brokerageType is percentage and profitLossType is brokerwise
            }),
          otherwise: () =>
            Yup.object().when('brokerageType', {
              is: (brokerageType, profitLossType) => profitLossType === 'brokerwise' && brokerageType === 'lotwise',
              then: () =>
                Yup.object().shape({
                  brokerage: Yup.object().shape({
                    nasdaq: numberValidtor('Nasdaq Brokerage is required'),
                    dowjones: numberValidtor('Dowjones Brokerage is required'),
                    spx: numberValidtor('S&P Brokerage is required'),
                    default: numberValidtor('Default Brokerage is required'),
                  }),
                }),
              otherwise: () => Yup.object().notRequired(), // If brokerageType is neither 'percentage' nor 'lotwise', or profitLossType is not 'brokerwise'
            }),
        }),

        broker: Yup.object().when('brokerageType', {
          is: (brokerageType) => brokerageType === 'percentage' && profitLossType === 'brokerwise',
          then: Yup.object().shape({
            brokerageValue: numberValidtor('Broker Brokerage Value is required')
              .min(0, 'Broker Brokerage must be at least 0')
              .max(100, 'Broker Brokerage must be at most 100'),
          }),
          otherwise: Yup.object().notRequired(),
        }),
      }),
    }),

    // COMEX: Yup.object().when('allowComex', {
    //   is: true,
    //   then: Yup.object().shape({
    //     blockedScripts: Yup.array().of(Yup.string().required('Blocked script is required')),

    //     brokerageType: Yup.string()
    //       .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
    //       .required('Comex Brokerage Type is required'),

    //     brokerageValue: Yup.number().when('brokerageType', {
    //       is: 'percentage',
    //       then: Yup.number()
    //         .required('Brokerage value for Comex is required')
    //         .min(0, 'Value must be at least 0')
    //         .max(100, 'Value must be at most 100'),
    //       otherwise: Yup.number().notRequired(),
    //     }),

    //     brokerage: Yup.object().when('brokerageType', {
    //       is: 'lotwise',
    //       then: Yup.object().shape({
    //         gold: numberValidtor('Gold Brokerage is required'),
    //         silver: numberValidtor('Silver Brokerage is required'),
    //         copper: numberValidtor('Copper Brokerage is required'),
    //         crudeoil: numberValidtor('Crude Oil Brokerage is required'),
    //         naturalgas: numberValidtor('Natural Gas Brokerage is required'),
    //       }),
    //     }),
    //   }),
    // }),
  });
};

const tradingSettingsValidators = (role, userType, EQUITY_SETTINGS_LIST, MCX_NAME_LISTS, manager) => {
  let schema;
  const profitLossType = manager?.profitLossType || 'adminwise';
  console.log('PRODFIT LOSS', profitLossType);
  if (userType !== 'Client') {
    if (userType === 'Admin') {
      schema = adminSchema;
    } else {
      schema = brokerSchema(profitLossType);
    }

    // schema = Yup.object().shape({
    //   NSE: Yup.object().when('allowEquity', {
    //     is: true,
    //     then: Yup.object()
    //       .shape({
    //         blockedScripts: Yup.array().of(Yup.string().required('Blocked script is required')).nullable(),

    //         brokerageType: Yup.string().when('NSE.brokerageType', {
    //           is: (userType, brokerageType) =>
    //             userType === 'Broker' ||
    //             (userType === 'Admin' && profitLossType === 'adminwise' && brokerageType === 'percentage'),
    //           then: Yup.string()
    //             .oneOf(['percentage', 'crorebase'], 'Invalid Brokerage Type')
    //             .required('Equity Brokerage Type is required'),
    //           otherwise: Yup.string().notRequired(),
    //         }),

    //         brokerageValue: Yup.number().when('NSE.brokerageType', {
    //           is: (userType, brokerageType) =>
    //             (userType === 'Broker' || (userType === 'Admin' && profitLossType === 'adminwise')) &&
    //             (brokerageType === 'percentage' || brokerageType === 'crorebase'),
    //           then: Yup.number()
    //             .required('Equity Brokerage Value is required')
    //             .typeError('Equity Brokerage Value must be a number')
    //             .when('NSE.brokerageType', {
    //               is: 'percentage',
    //               then: Yup.number()
    //                 .min(0, 'Equity Brokerage Value must be at least 0')
    //                 .max(100, 'Equity Brokerage Value must be at most 100'),
    //               otherwise: Yup.number().positive('Equity Brokerage Value must be positive'),
    //             }),
    //           otherwise: Yup.number().notRequired(),
    //         }),

    //         superAdminBrokerageValue: Yup.number().when('NSE.brokerageType', {
    //           is: (userType, brokerageType) =>
    //             userType === 'Broker' && profitLossType === 'brokerwise' && brokerageType === 'percentage',
    //           then: Yup.number()
    //             .required('Super Admin Brokerage is required')
    //             .typeError('Super Admin Brokerage must be a number')
    //             .min(0, 'Super Admin Brokerage must be at least 0')
    //             .max(100, 'Super Admin Brokerage must be at most 100'),
    //           otherwise: Yup.number().notRequired(),
    //         }),

    //         brokerBrokerageValue: Yup.number().when('NSE.brokerageType', {
    //           is: (userType, brokerageType) =>
    //             userType === 'Broker' && profitLossType === 'brokerwise' && brokerageType === 'percentage',
    //           then: Yup.number()
    //             .required('Broker Brokerage is required')
    //             .typeError('Broker Brokerage must be a number')
    //             .min(0, 'Broker Brokerage must be at least 0')
    //             .max(100, 'Broker Brokerage must be at most 100'),
    //           otherwise: Yup.number().notRequired(),
    //         }),
    //       })
    //       .test('sum-check', 'The sum of Super Admin Brokerage and Broker Brokerage must equal 100', (values) => {
    //         const { brokerageType, superAdminBrokerageValue, brokerBrokerageValue } = values || {};
    //         if (brokerageType === 'percentage' && superAdminBrokerageValue != null && brokerBrokerageValue != null) {
    //           const total = (superAdminBrokerageValue || 0) + (brokerBrokerageValue || 0);
    //           return total === 100;
    //         }
    //         return true;
    //       }),
    //   }),

    //   // Conditional fields based on allowMCX
    //   MCX: Yup.object().when('allowMCX', {
    //     is: true,
    //     then: Yup.object().shape({
    //       blockedScripts: Yup.array().of(Yup.object().required('Blocked script is required')),
    //       brokerageType: Yup.string().when('profitLossType', {
    //         is: 'adminwise',
    //         then: Yup.string()
    //           .oneOf(['percentage', 'lotwise'], 'Invalid Brokerage Type')
    //           .required('Brokerage Type is required'),
    //       }),
    //       brokerageValue: Yup.number().when('brokerageType', {
    //         is: 'percentage',
    //         then: Yup.number().required('Brokerage Value is required').positive('Brokerage Value must be positive'),
    //         otherwise: Yup.number().notRequired(),
    //       }),
    //       // Additional fields for lotwise brokerage
    //       brokerage: Yup.object().when('brokerageType', {
    //         is: 'lotwise',
    //         then: Yup.object().shape({
    //           metal: Yup.number().notRequired(),
    //           miniMetal: Yup.number().notRequired(),
    //           baseMetal: Yup.number().notRequired(),
    //           energy: Yup.number().notRequired(),
    //         }),
    //       }),
    //       superAdminBrokerageType: Yup.string().when(['userType', 'profitLossType'], {
    //         is: (userType, profitLossType) => userType === 'Broker' && profitLossType === 'brokerwise',
    //         then: Yup.string()
    //           .oneOf(['percentage', 'lotwise'], 'Invalid SuperAdmin Brokerage Type')
    //           .required('SuperAdmin Brokerage Type is required'),
    //       }),
    //       superAdminBrokerageValue: Yup.number().when('superAdminBrokerageType', {
    //         is: 'percentage',
    //         then: Yup.number()
    //           .required('SuperAdmin Brokerage Value is required')
    //           .positive('SuperAdmin Brokerage Value must be positive'),
    //         otherwise: Yup.number().notRequired(),
    //       }),
    //       // Additional fields for lotwise brokerage
    //       superAdminBrokerage: Yup.object().when('superAdminBrokerageType', {
    //         is: 'lotwise',
    //         then: Yup.object().shape({
    //           metal: Yup.number().notRequired(),
    //           miniMetal: Yup.number().notRequired(),
    //           baseMetal: Yup.number().notRequired(),
    //           energy: Yup.number().notRequired(),
    //         }),
    //       }),
    //     }),
    //     otherwise: Yup.object().notRequired(),
    //   }),
    //   COMEX: Yup.object().when('allowComex', {
    //     is: true,
    //     then: Yup.object().shape({
    //       brokerageValue: Yup.number().when('brokerageType', {
    //         is: 'percentage',
    //         then: Yup.number()
    //           .required('Brokerage value for MCX is required')
    //           .typeError('Brokerage Value is required')
    //           .min(0, 'Value must be at least 0')
    //           .max(100, 'Value must be at most 100'),
    //       }),
    //       blockedScripts: Yup.array().of(Yup.object()),
    //       brokerageType: Yup.string().oneOf(['percentage', 'lotwise']).required('Comex Brokerage Type is required'),
    //       brokerage: Yup.object().when('brokerageType', {
    //         is: 'lotwise',
    //         then: () =>
    //           Yup.object().shape({
    //             gold: numberValidtor('Gold Brokerage must be a number'),
    //             silver: numberValidtor('Silver Brokerage is required'),
    //             copper: numberValidtor('Copper Brokerage is required'),
    //             crudeoil: numberValidtor('crude oil Brokerage is required'),
    //             naturalgas: numberValidtor('Natural gas Brokerage is required'),
    //           }),
    //       }),
    //       // goldBrokerage: Yup.number().when('comexBrokerageType', {
    //       //   is: 'lotwise',
    //       //   then: Yup.number()
    //       //     .required('Gold Brokerage is required')
    //       //     .typeError('Gold Brokerage value must be a number'),
    //       //   otherwise: Yup.number(),
    //       // }),
    //       // silverBrokerage: Yup.number().when('comexBrokerageType', {
    //       //   is: 'lotwise',
    //       //   then: Yup.number()
    //       //     .required('Silver Brokerage is required')
    //       //     .typeError('Silver Brokerage value must be a number'),
    //       //   otherwise: Yup.number(),
    //       // }),
    //       // copperBrokerage: Yup.number().when('comexBrokerageType', {
    //       //   is: 'lotwise',
    //       //   then: Yup.number().required('Copper Brokerage is required').typeError('Copper Brokerage is required'),
    //       //   otherwise: Yup.number(),
    //       // }),
    //       // NGBrokerage: Yup.number().when('comexBrokerageType', {
    //       //   is: 'lotwise',
    //       //   then: Yup.number().required('NG Brokerage is required').typeError('NG Brokerage is required'),
    //       //   otherwise: Yup.number(),
    //       // }),
    //       // crudeBrokerage: Yup.number().when('comexBrokerageType', {
    //       //   is: 'lotwise',
    //       //   then: Yup.number().required('Crude Oil Brokerage is required').typeError('Crude Oil Brokerage is required'),
    //       //   otherwise: Yup.number(),
    //       // }),
    //     }),
    //   }),
    // });
  } else {
    schema = Yup.object().shape({
      balance: numberValidtor('Trading Capital is required'),
      currency: Yup.string()
        .required('Currency is required')
        .oneOf(['INR', 'USD'], 'Currency must be either INR or USD'),
      NSE: Yup.object().when('allowEquity', {
        is: true,
        then: Yup.object().shape({
          brokerageValue: numberValidtor('Brokerage is Required'),
          intradayExposure: numberValidtor('Intraday Exposure is required'),
          holdingExposure: numberValidtor('Holding Exposure is required'),
          refundBrokerage: numberValidtor('Refund Brokerage is required').test(
            'refundBrokerage',
            'RefundBrokerage Must be less then Brokerage',
            // eslint-disable-next-line func-names
            function (value) {
              const { brokerageValue } = this.parent;
              return value <= brokerageValue;
            }
          ),
          enabledLotBaseTrading: Yup.boolean().required('Eanble Lot Base Trading is required'),
          shareConfig: Yup.object().shape({
            nifty: equityLotValidator,
            bankNifty: equityLotValidator,
            finNifty: equityLotValidator,
            ...EQUITY_SETTINGS_LIST.reduce((acc, val) => {
              // const name = parseInt(val) || 'rest';
              acc[val] = equityLotValidator;
              return acc;
            }, {}),
            rest: equityLotValidator,
          }),
          tradeHoldTime: Yup.number()
            .required('Trade Hold Time Value is required')
            .typeError('Trade Hold Time Value must be a number')
            .min(0, 'Value must be at least 0'),
        }),
      }),
      MCX: Yup.object().when('allowMCX', {
        is: true,
        then: Yup.object().shape({
          maximumLotforSingleTrade: numberValidtor('Maximum Lot for Single Trade is required'),
          maximumOpenLotPerScript: numberValidtor('Maximum Open lot per script is required'),
          maximumOpenLotAllowed: numberValidtor('Total Lot Size for trade is required'),
          metal: commodityValidator,
          miniMetal: commodityValidator,
          baseMetal: commodityValidator,
          energy: commodityValidator,
          ...MCX_NAME_LISTS.reduce((acc, val) => {
            // const name = parseInt(val) || 'rest';
            acc[val] = commodityValidator;
            return acc;
          }, {}),
          tradeHoldTime: Yup.number()
            .required('Trade Hold Time Value is required')
            .typeError('Trade Hold Time Value must be a number')
            .min(0, 'Value must be at least 0'),
        }),
      }),
      COMEX: Yup.object().when('allowComex', {
        is: true,
        then: Yup.object().shape({
          maximumLotforSingleTrade: numberValidtor('Maximum Lot for Single Trade is required'),
          maximumOpenLotPerScript: numberValidtor('Maximum Open lot per script is required'),
          maximumOpenLotAllowed: numberValidtor('Total Lot Size for trade is required'),
          gold: commodityValidator,
          silver: commodityValidator,
          copper: commodityValidator,
          naturalgas: commodityValidator,
          crudeoil: commodityValidator,
          tradeHoldTime: Yup.number()
            .required('Trade Hold Time Value is required')
            .typeError('Trade Hold Time Value must be a number')
            .min(0, 'Value must be at least 0'),
        }),
      }),
      WINDEX: Yup.object().when(['allowWINDEX', 'currency'], {
        is: (allowWINDEX, currency) => allowWINDEX === true && currency === 'USD',
        then: Yup.object().shape({
          maximumLotforSingleTrade: numberValidtor('Maximum Lot for Single Trade is required'),
          maximumOpenLotPerScript: numberValidtor('Maximum Open lot per script is required'),
          maximumOpenLotAllowed: numberValidtor('Total Lot Size for trade is required'),
          nasdaq: commodityValidator,
          dowjones: commodityValidator,
          spx: commodityValidator,
          default: commodityValidator,
          tradeHoldTime: Yup.number()
            .required('Trade Hold Time Value is required')
            .typeError('Trade Hold Time Value must be a number')
            .min(0, 'Value must be at least 0'),
        }),
        otherwise: Yup.object().when('allowWINDEX', {
          is: true,
          then: Yup.object().shape({
            brokerageValue: numberValidtor('Brokerage is Required'),
            intradayExposure: numberValidtor('Intraday Exposure is required'),
            nasdaq: equityLotValidator,
            dowjones: equityLotValidator,
            spx: equityLotValidator,
            default: equityLotValidator,
            tradeHoldTime: Yup.number()
              .required('Trade Hold Time Value is required')
              .typeError('Trade Hold Time Value must be a number')
              .min(0, 'Value must be at least 0'),
          }),
        }),
      }),
      NSEIX: Yup.object().when('allowWINDEX', {
        is: true,
        then: Yup.object().shape({
          maximumLotforSingleTrade: numberValidtor('Maximum Lot for Single Trade is required'),
          maximumOpenLotPerScript: numberValidtor('Maximum Open lot per script is required'),
          maximumOpenLotAllowed: numberValidtor('Total Lot Size for trade is required'),
          giftNifty: commodityValidator,
          tradeHoldTime: Yup.number()
            .required('Trade Hold Time Value is required')
            .typeError('Trade Hold Time Value must be a number')
            .min(0, 'Value must be at least 0'),
        }),
      }),
    });
  }
  return schema;
};

export default tradingSettingsValidators;
